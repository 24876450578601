
import { importSync as i } from '@embroider/macros';
let d = window.define;
d("@embroider/macros/es-compat2", function(){ return i("@embroider/macros/es-compat2");});
d("@embroider/macros/runtime", function(){ return i("@embroider/macros/runtime");});
d("@ember/test-waiters/build-waiter", function(){ return i("@ember/test-waiters/build-waiter");});
d("ember-test-waiters/index", function(){ return i("@ember/test-waiters/ember-test-waiters/index");});
d("@ember/test-waiters/index", function(){ return i("@ember/test-waiters/index");});
d("@ember/test-waiters/token", function(){ return i("@ember/test-waiters/token");});
d("@ember/test-waiters/types/index", function(){ return i("@ember/test-waiters/types/index");});
d("@ember/test-waiters/wait-for-promise", function(){ return i("@ember/test-waiters/wait-for-promise");});
d("@ember/test-waiters/wait-for", function(){ return i("@ember/test-waiters/wait-for");});
d("@ember/test-waiters/waiter-manager", function(){ return i("@ember/test-waiters/waiter-manager");});
d("@embroider/util/ember-private-api", function(){ return i("@embroider/util/ember-private-api");});
d("@embroider/util/index", function(){ return i("@embroider/util/index");});
d("@embroider/util/services/ensure-registered", function(){ return i("@embroider/util/services/ensure-registered");});
import "@embroider/macros/-embroider-implicit-modules.js";
import "@ember/test-waiters/-embroider-implicit-modules.js";
import "@embroider/util/-embroider-implicit-modules.js";
import "ember-cli-htmlbars/-embroider-implicit-modules.js";
import "ember-destroyable-polyfill/-embroider-implicit-modules.js";
